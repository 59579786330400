import React from 'react';
import { FileUpload } from './FileUpload';

const Report = () => (
  <div className="container mt-4">
    <h4 className="display-4 text-center mb-4">
      <i className="fas fa-file-medical-alt"></i> Reportajes
    </h4>

    <FileUpload />
  </div>
);

export default Report;