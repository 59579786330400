import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";

export default function Register() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const [displayName, setDisplayName] = useState();
  const [role, setRole] = useState({});
  const [users, setUsers] = useState([]);
  const [error, setError] = useState();
  const [keyword, setKeyword] = useState();

  const { userData, setUserData } = useContext(UserContext);

  useEffect(() => {
    fetchRegisteredUsers();
  }, []);

  const fetchRegisteredUsers = async () => {
    {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUsers(data);
          setEmail("");
          setPassword("");
          setPasswordCheck("");
          setDisplayName("");
          setRole("");
        });

    }
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      if (userData.user) {
        const creator = userData.user.displayName;
        const newUser = { email, password, passwordCheck, displayName, role, creator };
        await Axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/users/register`, newUser);
        alert("Usuario insertado correctamente");
        fetchRegisteredUsers();
      }
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };

  const getCurrentDate = (date) => {
    return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
  };

  const getStateIcon = (state) => {
    let renderIcon = null;
    if (state) {
      renderIcon = <i className="material-icons notranslate">delete</i>;
    } else {
      renderIcon = <i className="material-icons notranslate">undo</i>;
    }
    return renderIcon;
  };

  // const updateUser = (id = false) => {
  //   history.push(`/admin/update?q=${id}`)
  // }

  const editUser = (id = false) => {
    try {
      if (window.confirm("¿Desea cambiar el estado de este usuario?")) {
        fetch(`/users/edit/${id}/${userData.user.displayName}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            alert("Usuario actualizado correctamente");
            fetchRegisteredUsers();
          })
          .catch((err) => console.error("Entró al error ", err));
      }
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };

  return (
    <div className="page">
      <div className="container">
        <h2>Registrar</h2>
        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}

        {
          userData.user.role === 'administrador' ?
            <>
              <form className="form" onSubmit={submit}>
                <label htmlFor="register-email">Email</label>
                <input
                  id="register-email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />

                <label htmlFor="register-password">Password</label>
                <input
                  id="register-password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <input
                  type="password"
                  placeholder="Verificar password"
                  onChange={(e) => setPasswordCheck(e.target.value)}
                  value={passwordCheck}
                />

                <label htmlFor="register-display-name">Nombre de Usuario</label>
                <input
                  id="register-display-name"
                  type="text"
                  onChange={(e) => setDisplayName(e.target.value)}
                  value={displayName}
                />

                <label htmlFor="role">Rol: </label>
                <select name="role" id="role" value={role} onChange={(e) => setRole(e.target.value)}>
                  <option>--- Seleccione una opción ---</option>
                  <option value="administrador">Administrador</option>
                  <option value="edicion">Edición</option>
                </select>
                <br></br>
                <br></br>
                <input type="submit" value="Registrar" />
              </form>

              <div>
                Escriba el usuario a buscar:
                <div className="input-field inline">
                  <input
                    id="key_word"
                    name="key_word"
                    onChange={(e) => setKeyword(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
            </>
            :
            ''
        }
        <br></br>
        <br></br>
      </div>
      <table className="striped" id="usersReport">
        <thead>
          <tr>
            <th>Número</th>
            <th>Email</th>
            <th>Username</th>
            <th>Rol</th>
            <th>Estado</th>
            <th>Fecha de creación</th>
            <th>Creador</th>
            <th>Fecha de Actualización</th>
            <th>Modificador</th>
            <th>Modificar usuario</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            if (user.displayName.includes(keyword) || keyword === undefined) {
              return (
                userData.user.role === 'administrador' || user.displayName === userData.user.displayName ?
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>
                      <p style={{ width: "200px" }}>{user.email}</p>
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>{user.displayName}</p>
                    </td>
                    <td>
                      <p style={{ width: "90px" }}>{user.role}</p>
                    </td>
                    <td>
                      <p style={{ width: "65px" }}>
                        {user.status ? "Activo" : "Inactivo"}
                      </p>
                    </td>
                    <td>
                      <p style={{ width: "170px" }}>
                        {user.created ? getCurrentDate(user.created) : ""}
                      </p>
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>{user.creator}</p>
                    </td>
                    <td>
                      <p style={{ width: "170px" }}>
                        {user.updated ? getCurrentDate(user.updated) : ""}
                      </p>
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>{user.modificator}</p>
                    </td>
                    <td>
                      {
                        userData.user.role !== 'administrador'?
                          '':
                          <button
                            className="btn"
                            style={{ margin: "4px" }}
                            onClick={() => editUser(user._id)}
                          >
                            {getStateIcon(user.status)}
                          </button>
                      }
                      <Link to={`/admin/update?id=${user._id}`}>
                        <i className="material-icons notranslate">perm_identity</i>
                      </Link>
                    </td>
                  </tr> :
                  ''
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
